<template>
    <div class="main-layout">
        <!-- Modals -->
        <modals v-if="!isAuthPath"></modals>
        <auth-modals v-if="isAuthPath"></auth-modals>
        <!-- Data Managers -->
        <data-managers/>
        <action-bar></action-bar>
        <!-- Navigation -->
        <div class="page-wrapper">
            <beta-options v-if="betaOptionsShowing"></beta-options>
            <navigation v-if="showNav && !isAuthPath"/>
            <navigation-for-authentication v-if="isAuthPath" />
            <div class="page">
                <!-- App Router -->
                <router-view class="page-view" />
                <div v-if="pageLoading" class="spinner-overlay">
                    <spinner class=""></spinner>
                </div>
            </div>
        </div>

        <copy-paste></copy-paste>
    </div>
</template>
<style>
.page-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.page {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.page-view {
    position: relative;
    flex-grow: 1;
}
</style>
<script>

import Navigation from './Nav';
import BetaOptions from '@/components/Navigation/BetaOptions'
import Spinner from '@/components/Controls/Spinner';
import DataManagers from "./DataManagers"
import * as Pendo from '@/services/Pendo'

import {mapGetters} from 'vuex';
import * as $ from 'jquery'
import CopyPaste from "@/components/DataManagement/CopyPaste";
import RouteContext from '@/utilities/RouteContext'
import Modals from "./Modals";
import NavigationForAuthentication from "@/layouts/NavAuth";
import AuthModals from "@/layouts/AuthModals";
import ActionBar from "../components/Navigation/ActionBar";
import features from "../Features";


export default {
    name: "MainLayout",
    mixins: [RouteContext],
    data() {
        return {
            betaOptionsShowing: false,
            keysPressed: [],
            showNav: true,
            pageLoading: false,
            pageItemsLoading: 0,
        }
    },
    watch: {
        keysPressed() {
            this.checkForToggle()
        },
        user() {
            if (this.user && !this.hideSupportApps && this.$intercom) {
                this.loadIntercom()
            } else {
                this.removeIntercom();
            }
            this.initializeApp()
        }
    },
    computed: {
        ...mapGetters({
            user: 'currentUser',
            accountsLastFetchedAt: 'accountsLastFetchedAt',
            topic: 'currentTopic',
            currentAccountOverview: 'currentAccountOverview',
            accountFeatures: 'accountFeatures',
            features: 'features',
            collection: 'currentCollection',
            signupPath: 'signupPath',
            hideSupportApps: 'hideSupportApps'
        }),
        accountsAreStale() {
            let now = new Date;
            let lastFetch = new Date(this.accountsLastFetchedAt);

            if (!lastFetch) {
                return true;
            }

            return (Math.abs(now - lastFetch) > 10000)
        },
        isAuthPath() {
            // Regex: ^/?([a-z\-]+)([\/a-z0-9\-?=#]+)? or ^/ find-this /but-not?this=random#junk%20/
            const strPath = this.$route && this.$route.path.replace(
                new RegExp('^/?([a-z-]+)([a-z0-9-?=#%/]+)?'), '$1');
            const arrAuthPaths = [
                'trial-signup',
                'trial-sign-up',
                'signup',
                'sign-up',
                'trial-start',
                'login',
                'log-in',
                'logout',
                'log-out',
                'forgot-password',
                'reset',
            ];
            return arrAuthPaths.some(path => path === strPath);
        },
    },
    mounted() {
        this.initializeApp()
        document.onkeydown = this.keyPressed;
        document.onkeyup = this.keyReleased;
        if(this.$intercom) this.loadIntercom();
        // if (this.user && this.account) {
        //         this.loadPendo();
        //     }
        window.Events.$on('startPageLoad', () => {
            this.pageLoading = true;
        })
        window.Events.$on('finishPageLoad', () => {
            this.pageLoading = false;
        });
        window.Events.$on('startPageLoadItem', () => {
            this.pageItemsLoading++;
            this.pageLoading = true;
        })
        window.Events.$on('finishPageLoadItem', () => {
            this.pageItemsLoading--;
            this.pageLoading = this.pageItemsLoading !== 0;
        });
        window.Events.$on('awaitPageLoadItem', itemPromise => {
            this.pageItemsLoading++;
            this.pageLoading = true;
            itemPromise.then(() => {
                this.pageItemsLoading--;
                this.pageLoading = this.pageItemsLoading !== 0;
            }).catch(e => {
                console.error(e);
            })
        })

        $(document).on('click', '.faux-router-link', (e) => {
            e.preventDefault();
            let route = $(e.target).closest('a').attr('data-to');
            if (route)
                this.$router.push(route);
        });
        this.goToAccount()
    },
    components: {
        ActionBar,
        NavigationForAuthentication,
        AuthModals,
        Modals,
        CopyPaste,
        Navigation,
        BetaOptions,
        DataManagers,
        Spinner
    },
    methods: {
        initializeApp() {
            console.log('* Main.vue MainLayout mounted')
            console.log('* Main.vue entrypoint about to initialize')
            this.$store.dispatch('initialize');
        },
        checkForToggle() {
            let activate = (this.keysPressed.indexOf(16) > -1 && this.keysPressed.indexOf(17) > -1 &&
                this.keysPressed.indexOf(191) > -1)
            if (activate) {
                window.Events.$emit("specialComboPressed");
            }
        },
        keyPressed(e) {
            this.keysPressed.push(e.keyCode)
        },
        keyReleased(e) {
            this.keysPressed.splice(this.keysPressed.indexOf(e.keyCode), 1)

            if (e.key === ' ' && e.ctrlKey) {
                if (features.actionBar)
                    window.Events.$emit('show_actionBar', {})
            }
        },
        toggleBetaOptions() {
            this.betaOptionsShowing = !this.betaOptionsShowing
            this.showNav = !this.showNav
        },
        loadIntercom() {
            this.$intercom.boot({
                user_id: this.user.id,
                name: this.user.name,
                email: this.user.email,
            });
        },
        loadPendo() {
            Pendo.initializePendo({userId: this.user.id, ...this.user},
                {accountId: this.currentAccountOverview.id, ...this.currentAccountOverview})
        },
        removeIntercom() {
            this.$intercom.shutdown()
        }
    }
}

</script>
