import * as types from '../mutation-types'
import {knownTags} from "@/Constants";
import {hasValue} from "../../utilities/utils";
import {mergePublishers} from "../../services/Publishers";

const state = {
    lastTopic : {},
    lastCollection : {},
    viewerType : 'topics',
    hasSuggestionId : false,
}

const getters = {
    hasSuggestions : state => {
            switch(state.viewerType){
                case 'topics':
                    return state.lastTopic;
                case 'collections':
                    return state.lastCollection;
        }
    },
    // hasSuggestionsSettings : state => {
    //
    //     let storedSettings = state.hasSuggestionsSettings[state.hasSuggestionId];
    //     if (storedSettings){
    //         return storedSettings
    //     }
    //
    //     let topics = {
    //         contentType: 'All',
    //         sortBy: 'Relevance',
    //         time: '2 Months',
    //         age: 60,
    //         customTime: '',
    //         geography: ['US'],
    //         excludedSources: [],
    //         evergreen: false
    //     }
    //
    //     let collections = {
    //         contentType: 'All',
    //         sortBy: 'Recency',
    //         time: 'Forever',
    //         age: -1,
    //         customTime: '',
    //         geography: ['US'],
    //         excludedSources: [],
    //         evergreen: true
    //     }
    //
    //
    //     switch (state.viewerType) {
    //         case "topics":
    //             return topics;
    //         case "collections":
    //             return collections;
    //     }
    //
    //
    // }
}

const actions = {
    async loadSuggestionView({commit, dispatch, getters}) {
        let id = getters.hasSuggestions && getters.hasSuggestions.id;
        let type = getters.hasSuggestions && getters.hasSuggestions.type;
        let collection = getters.currentCollection;
        let topic = getters.currentTopic;
        console.log('loadSuggestionView', { id, type, collection, topic })

        //short circuit if cannot load suggestion view
        if(!id || !type || !collection || !topic) {
            console.log('Cannot load suggestion view')
            return;
        }
        async function doTask(action, payload={}) {
            let idNow = getters.hasSuggestions && getters.hasSuggestions.id;
            if (id !== idNow) {
                console.log('selected view changed', {old:id, newId:idNow})
                throw "canceled"
            }
            try {
                await dispatch(action, {...payload, hasSuggestionId: id})
            } catch (e) {
                if (e === 'canceled')
                    throw e;
                else
                    console.error(e)

            }
        }
        try {
            await commit('START_LOADING_SUGGESTION_VIEW')
            await commit(types.BEGIN_DOWNLOADING_SUGGESTIONS_LIST)
            await dispatch('clearSuggestions')
            await Promise.all([
                await doTask('loadFilterViewList', {}),
                (async () => {
                    await doTask('loadSettingsForHasContent', {});
                    await doTask('loadFiltersFromSettings', {});
                    await doTask('loadTags', {type, id});
                    await doTask('loadSuggestions', {keep: true})
                })()
            ])

        } catch (e) {
            if (e !== 'canceled') {
                console.error(e)
            }
        }
    },
    setIsLoadingSources : ({commit}, bool) => {
        commit("SET_IS_LOADING_SOURCES", bool);
    },
    setIsLoadingTags : ({commit}, bool) => {
        commit("SET_IS_LOADING_TAGS", bool);
    },
}

const mutations = {
    SET_SUGGESTION_VIEWER_TYPE: (state, { type }) => {
        state.viewerType = type;
        switch (type) {
            case "topics":
                state.hasSuggestions = state.lastTopic
                state.hasSuggestionId = state.lastTopic.id
                break;
            case "collections":
                state.hasSuggestions = state.lastCollection
                state.hasSuggestionId = state.lastCollection.id
                break;
        }
    },
    [types.SET_CURRENT_TOPIC](state, {topic}){
        if (topic){
            state.lastTopic = { type: 'topic', id: topic.id }
            state.hasSuggestionId = topic.id
        } else  {
            state.lastTopic = {}
        }
    },
    SET_CURRENT_COLLECTION: (state, { collection }) => {
        console.log('* suggestion-management.js SET_CURRENT_COLLECTION setCurrentCollection collection', collection)
        if (collection){
            state.lastCollection = { type: 'collection', id: collection.id }
            state.hasSuggestionId = collection.id
        } else  {
            state.lastCollection = {}
        }
    },
    [types.DELETE_COLLECTION](state, {collection}){
        if (collection.id === state.lastCollection.id){
            state.lastCollection = {};
        }
        if (collection.id === state.hasSuggestionId){
            state.hasSuggestionId = false
        }
    },
    [types.DELETE_TOPIC](state, {topic}){
        if (topic.id === state.lastTopic.id) {
            state.lastTopic = {};
        }
        if (topic.id === state.hasSuggestionId){
            state.hasSuggestionId = false
        }
    },
    [types.USER_DID_LOG_OUT](state){
        state.lastTopic = {}
        state.lastCollection = {}
        state.viewerType = 'topics';
        state.isLoadingSources = false;
        state.hasSuggestionId = false;
        state.sources = {};
        state.excludedSources = {};
        state.hasSuggestionsSettings = {};
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
